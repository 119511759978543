import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'navigation-container',
  templateUrl: './navigation-container.component.html',
  styleUrls: ['./navigation-container.component.sass']
})
export class NavigationContainerComponent implements OnInit {
  @Input() titles: [string];
  @Input() references: [string];

  showMenu = false;

  constructor() { 

  }

  ngOnInit() {
  }

  toggle() { 
    this.showMenu = !this.showMenu
  }
}
