import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'podcast-player',
  templateUrl: './podcast-player.component.html',
  styleUrls: ['./podcast-player.component.sass']
})
export class PodcastPlayerComponent implements OnInit {
  @Input() podcastURL: string;

  constructor() { }

  ngOnInit() {
  }

}
