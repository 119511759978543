import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-button-container',
  templateUrl: './contact-button-container.component.html',
  styleUrls: ['./contact-button-container.component.sass']
})
export class ContactButtonContainerComponent implements OnInit {
  @Input() linkNames: [string];
  @Input() links: [string];

  constructor() { }

  ngOnInit() {
  }
}
