import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-creator-container',
  templateUrl: './creator-container.component.html',
  styleUrls: ['./creator-container.component.sass']
})
export class CreatorContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
