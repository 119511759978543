import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-container',
  templateUrl: './footer-container.component.html',
  styleUrls: ['./footer-container.component.sass']
})
export class FooterContainerComponent implements OnInit {
  @Input() links: [string];
  @Input() linkNames: [string];

  constructor() { }

  ngOnInit() {
  }

}
