import { Component, OnInit, Input } from '@angular/core';
import { Project } from 'src/app/models/project';
import { Website } from 'src/app/models/website';

import { WebsiteData } from 'src/app/data/website';

@Component({
  selector: 'app-home-container',
  templateUrl: './home-container.component.html',
  styleUrls: ['./home-container.component.sass']
})

export class HomeContainerComponent implements OnInit {
  website: Website

  constructor() { 
    this.website = WebsiteData
  }
  ngOnInit() { }
}