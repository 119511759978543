import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'podcast-episode-row',
  templateUrl: './podcast-episode-row.component.html',
  styleUrls: ['./podcast-episode-row.component.sass']
})

export class PodcastEpisodeRowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
