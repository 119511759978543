import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactButtonComponent } from './components/elements/contact-button/contact-button.component';
import { ContactButtonContainerComponent } from './components/containers/contact-button-container/contact-button-container.component';
import { NavigationContainerComponent } from './components/containers/navigation-container/navigation-container.component';
import { AboutContainerComponent } from './components/containers/about-container/about-container.component';
import { HomeContainerComponent } from './components/containers/home-container/home-container.component';
import { FooterContainerComponent } from './components/containers/footer-container/footer-container.component';
import { ThetealpickleAppContainerComponent } from './components/containers/thetealpickle-app-container/thetealpickle-app-container.component';
import { DownloadAppButtonComponent } from './components/elements/download-app-button/download-app-button.component';
import { ProjectContainerComponent } from './components/containers/project-container/project-container.component';
import { ProjectButtonComponent } from './components/elements/project-button/project-button.component';
import { PodcastPlayerComponent } from './components/elements/podcast-player/podcast-player.component';
import { PodcastSelectionContainerComponent } from './components/containers/podcast-selection-container/podcast-selection-container.component';
import { PodcastEpisodesContainerComponent } from './components/containers/podcast-episodes-container/podcast-episodes-container.component';
import { PodcastEpisodeRowComponent } from './components/elements/podcast-episode-row/podcast-episode-row.component';
import { PodcastContainerComponent } from './components/containers/podcast-container/podcast-container.component';
import { ContactContainerComponent } from './components/containers/contact-container/contact-container.component';
import { CreatorContainerComponent } from './components/containers/creator-container/creator-container.component';

@NgModule({
  declarations: [
    AppComponent,
    ContactButtonComponent,
    ContactButtonContainerComponent,
    NavigationContainerComponent,
    AboutContainerComponent,
    HomeContainerComponent,
    FooterContainerComponent,
    ThetealpickleAppContainerComponent,
    DownloadAppButtonComponent,
    ProjectContainerComponent,
    ProjectButtonComponent,
    PodcastPlayerComponent,
    PodcastSelectionContainerComponent,
    PodcastEpisodesContainerComponent,
    PodcastEpisodeRowComponent,
    PodcastContainerComponent,
    ContactContainerComponent,
    CreatorContainerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
