import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeContainerComponent } from './components/containers/home-container/home-container.component';
import { ProjectContainerComponent } from './components/containers/project-container/project-container.component';
import { ContactContainerComponent } from './components/containers/contact-container/contact-container.component';
import { PodcastContainerComponent } from './components/containers/podcast-container/podcast-container.component';
import { CreatorContainerComponent } from './components/containers/creator-container/creator-container.component';

const routes: Routes = [
  {path: '', component: HomeContainerComponent},
  {path: 'creator', component: CreatorContainerComponent},
  {path: 'projects', component: ProjectContainerComponent},
  {path: 'contact', component: ContactContainerComponent},
  {path: 'podcasts', component: PodcastContainerComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}