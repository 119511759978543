import { Website } from '../models/website';
import { Project } from '../models/project';

export const WebsiteData: Website = {
    title: 'THETEALPICKLE',
    titles: ['Engineer.', 'Creator.', 'Entrepreneur.'],
    // navigationReferences: [],
    // navigationTitles: [],
    navigationTitles: ['creator', 'studio', 'podcast'],
    navigationReferences: ['/creator', 'http://thetealpickle.studio', 'https://picklejuice.fm'],
    linkNames: ['github', 'youtube', 'linkedin', 'tik-tok', 'instagram'],
    links: ['https://www.github.com/thetealpickle', 
            'https://www.youtube.com/channel/UCpLkwXjJZlletCgANrJLrsg/',
            'https://www.linkedin.com/in/thetealpickle',
            'https://www.tiktok.com/@thetealpickleco',
            'https://www.instagram.com/thetealpickle'
    ],
    privacyNames: ['privacy policy', 'terms of use'],
    privacyLinks: ['https://app.termly.io/document/privacy-policy/14f02105-b9db-42a2-b12e-f88f657c8fd1', 
            'https://app.termly.io/document/terms-of-use-for-ios-app/869f8971-4fbf-4a76-b2cb-859c79a1ee89'
    ],
    about: []
}

