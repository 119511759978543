import { Project } from '../models/project';

export const ProjectData: Project[] = [
    {
        title: 'THETEALPICKLE',
        tagline: '@thetealpickle universe key',
        demoLinks: ['https://apps.apple.com/us/app/thetealpickle/id1478766318?ls=1'],
        demoLinkNames: ['app store']
    },
    {
        title: 'Scribbles',
        tagline: 'Drawing in Augmented Reality',
        demoLinks: ['https://apps.apple.com/us/app/scribbles-drawing-in-ar/id1404117866'],
        demoLinkNames: ['app store']
    },
    {
        title: 'Junction',
        tagline: 'The new age business card',
        demoLinks: ['https://itunes.apple.com/us/app/junction-social-card/id1376617547?mt=8',
                    'http://junction.theflawlesshack.com/'],
        demoLinkNames: ['app store',
            'website']
    },
    {
        title: 'Paw',
        tagline: 'Rock, Paper, Scissors iOS app',
        demoLinks: ['http://paw.theflawlesshack.com/',
                    'https://github.com/theFlawlessHack/paw-ios'],
        demoLinkNames: ['website',
            'code']
    },
    {
        title: 'Yak',
        tagline: 'A slack clone',
        demoLinks: ['https://devpost.com/software/yak',
                    'https://github.com/theFlawlessHack/yak-ios'],
        demoLinkNames: ['website',
            'code']
    },
    {
        title: 'Catalog',
        tagline: 'Gamifying cataloging',
        demoLinks: ['https://devpost.com/software/catalog'],
        demoLinkNames: ['website']
    },
    {
        title: 'yHealth',
        tagline: 'AR Healthcare options',
        demoLinks: ['https://devpost.com/software/yhealth'],
        demoLinkNames: ['website']
    },
    {
        title: 'Another One',
        tagline: 'DJ Khaled Quote Generator Keyboard',
        demoLinks: ['https://devpost.com/software/dabest'],
        demoLinkNames: ['website']
    },
    {
        title: 'yo! Card',
        tagline: 'Virtual business card',
        demoLinks: ['https://devpost.com/software/yodat'],
        demoLinkNames: ['website']
    },
    {
        title: 'Keyfind',
        tagline: 'A custom keyboard to connect with locals',
        demoLinks: ['https://devpost.com/software/keyfind'],
        demoLinkNames: ['website']
    },
    {
        title: 'Happy Heart',
        tagline: 'Mental Illness trigger tracker',
        demoLinks: ['https://devpost.com/software/happy-heart'],
        demoLinkNames: ['website']
    },
    {
        title: 'Tag Buddy',
        tagline: 'Image Analysis and Tagging with Artificial Intelligence',
        demoLinks: ['https://devpost.com/software/tag-buddy'],
        demoLinkNames: ['website']
    },
    {
        title: 'Sugar Trails',
        tagline: 'Visualize and Ordering system for diabetics',
        demoLinks: ['https://devpost.com/software/sugar-trails-zou0tb'],
        demoLinkNames: ['website']
    },
]
