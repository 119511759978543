import { Component, OnInit, Input, Output } from '@angular/core';
import { Website } from 'src/app/models/website';

import { WebsiteData } from 'src/app/data/website';

@Component({
  selector: 'app-podcast-container',
  templateUrl: './podcast-container.component.html',
  styleUrls: ['./podcast-container.component.sass']
})
export class PodcastContainerComponent implements OnInit {
  website: Website
  @Output() currentPodcast: string = 'https://share.transistor.fm/e/build-your-saas/playlist/dark'

  constructor() { 
    this.website = WebsiteData
  }
  
  ngOnInit() { }
}