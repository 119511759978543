import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'project-button',
  templateUrl: './project-button.component.html',
  styleUrls: ['./project-button.component.sass']
})
export class ProjectButtonComponent implements OnInit {
  @Input() project: Project;

  constructor() { }

  ngOnInit() {
  }

}
