import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/models/project';

import { ProjectData } from 'src/app/data/project';

@Component({
  selector: 'app-project-container',
  templateUrl: './project-container.component.html',
  styleUrls: ['./project-container.component.sass']
})
export class ProjectContainerComponent implements OnInit {
  @Input() projects: Project[];

  constructor() { 
    this.projects = ProjectData
  }

  ngOnInit() {
  }

}
