import { Component, OnInit, Output } from '@angular/core';
import { Website } from 'src/app/models/website';

import { WebsiteData } from 'src/app/data/website';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  website: Website

  constructor() { 
    this.website = WebsiteData
  }
  ngOnInit() {}
}